'use client';

import { FC, useEffect } from 'react';
import { IUseAdsSecParams } from 'components/page/advertisement/listing/hooks/ads/useAdsSecParams';


interface IHead {
    adsPag: string,
    adsSec: IUseAdsSecParams | string,
}

export const Head: FC<IHead> = (props: IHead) => {
    useEffect(() => {
        if (!window['googletag']) {
            window['googletag'] = [];
        }

        if (typeof window['googletag'] === 'object') {

            const loadHead = () => {
                window['googletag'] = window['googletag'] || {};
                window['googletag'].cmd = window['googletag'].cmd || [];
                const sasSlots = [];
                const adsSec = [];
                const adsPag = [props.adsPag];

                Object.keys(props.adsSec).forEach(key => {
                    adsSec.push(props.adsSec[key]);
                });

                window['adsPrefix'] = 'c_';
                window['adsSec'] = adsSec;
                window['adsPag'] = adsPag;
                window['sasReqId'] = (Math.round(Math.random()*1e20)).toString(36);


                const defineAds = () => {
                    const mobileTabletOnly = window['googletag'].sizeMapping().addSize([0, 0], [[250, 250], [300, 50], [300, 100], [300, 150], [300, 250], [300, 300], 'fluid']).addSize([768, 300], []).build();
                    const desktopOnlyST = window['googletag'].sizeMapping().addSize([0, 0], []).addSize([768, 300], [[728, 200]]).addSize([970, 200], [[728, 200], [970, 250], [970, 90]]).addSize([990, 200], [[728, 200], [970, 250], [970, 90], [990, 300], [990, 250], [990, 200], [990, 90]]).addSize([1020, 200], [[728, 200], [970, 250], [970, 90], [990, 300], [990, 250], [990, 200], [990, 90], [1020, 200]]).addSize([1200, 400], [[728, 200], [970, 250], [970, 90], [990, 300], [990, 250], [990, 200], [990, 90], [1020, 200], [1200, 400]]).build();
                    const desktopOnlyBL = window['googletag'].sizeMapping().addSize([0, 0], []).addSize([1200, 300], [[728, 90], [728, 150]]).build();
                    const desktopOnlyQL = window['googletag'].sizeMapping().addSize([0, 0], []).addSize([1200, 300], [[300, 300]]).build();
                    const desktopOnlySC = window['googletag'].sizeMapping().addSize([0, 0], []).addSize([768, 300], [[250, 250], [300, 100], [300, 150], [300, 250], [300, 300], [300, 600], 'fluid']).build();

                    sasSlots['mobile-top'] = window['googletag'].defineSlot('/31040149,22425509359/nehnutelnosti/mobile/mobile-top', [[250, 250], [300, 50], [300, 100], [300, 150], [300, 250], [300, 300], 'fluid'], 'div-gpt-ad-nehnutelnosti-mobile-mobile-top').setCollapseEmptyDiv(true,true).defineSizeMapping(mobileTabletOnly).addService(window['googletag'].pubads());

                    if (window['adsPag'] && window['adsPag'][0]) {
                        switch (window['adsPag'][0]) {
                        case 'detail':
                            sasSlots['square'] = window['googletag'].defineSlot('/31040149,22425509359/nehnutelnosti/desktop/square', [[250, 250], [300, 100], [300, 150], [300, 250], [300, 300], [300, 600], 'fluid'], 'div-gpt-ad-nehnutelnosti-desktop-square').defineSizeMapping(desktopOnlySC).addService(window['googletag'].pubads());
                            break;
                        case 'list':
                            sasSlots['screen-top'] = window['googletag'].defineSlot('/31040149,22425509359/nehnutelnosti/desktop/screen-top', [[728, 200], [970, 250], [970, 90], [990, 300], [990, 250], [990, 200], [990, 90], [1020, 200], [1200, 400]], 'div-gpt-ad-nehnutelnosti-desktop-screen-top').setCollapseEmptyDiv(true,true).defineSizeMapping(desktopOnlyST).addService(window['googletag'].pubads());
                            sasSlots['banner-list-1'] = window['googletag'].defineSlot('/31040149,22425509359/nehnutelnosti/desktop/banner-list-1', [[728, 90], [728, 150]], 'div-gpt-ad-nehnutelnosti-desktop-banner-list-1').defineSizeMapping(desktopOnlyBL).addService(window['googletag'].pubads());
                            sasSlots['banner-list-2'] = window['googletag'].defineSlot('/31040149,22425509359/nehnutelnosti/desktop/banner-list-2', [[728, 90], [728, 150]], 'div-gpt-ad-nehnutelnosti-desktop-banner-list-2').defineSizeMapping(desktopOnlyBL).addService(window['googletag'].pubads());
                            sasSlots['banner-list-3'] = window['googletag'].defineSlot('/31040149,22425509359/nehnutelnosti/desktop/banner-list-3', [[728, 90], [728, 150]], 'div-gpt-ad-nehnutelnosti-desktop-banner-list-3').defineSizeMapping(desktopOnlyBL).addService(window['googletag'].pubads());
                            sasSlots['banner-list-4'] = window['googletag'].defineSlot('/31040149,22425509359/nehnutelnosti/desktop/banner-list-4', [[728, 90], [728, 150]], 'div-gpt-ad-nehnutelnosti-desktop-banner-list-4').defineSizeMapping(desktopOnlyBL).addService(window['googletag'].pubads());
                            sasSlots['mobile-banner-list-1'] = window['googletag'].defineSlot('/31040149,22425509359/nehnutelnosti/mobile/banner-list-1', [[250, 250], [300, 50], [300, 100], [300, 150], [300, 250], [300, 300], 'fluid'], 'div-gpt-ad-nehnutelnosti-mobile-banner-list-1').defineSizeMapping(mobileTabletOnly).addService(window['googletag'].pubads());
                            sasSlots['mobile-banner-list-2'] = window['googletag'].defineSlot('/31040149,22425509359/nehnutelnosti/mobile/banner-list-2', [[250, 250], [300, 50], [300, 100], [300, 150], [300, 250], [300, 300], 'fluid'], 'div-gpt-ad-nehnutelnosti-mobile-banner-list-2').defineSizeMapping(mobileTabletOnly).addService(window['googletag'].pubads());
                            sasSlots['mobile-banner-list-3'] = window['googletag'].defineSlot('/31040149,22425509359/nehnutelnosti/mobile/banner-list-3', [[250, 250], [300, 50], [300, 100], [300, 150], [300, 250], [300, 300], 'fluid'], 'div-gpt-ad-nehnutelnosti-mobile-banner-list-3').defineSizeMapping(mobileTabletOnly).addService(window['googletag'].pubads());
                            sasSlots['mobile-banner-list-4'] = window['googletag'].defineSlot('/31040149,22425509359/nehnutelnosti/mobile/banner-list-4', [[250, 250], [300, 50], [300, 100], [300, 150], [300, 250], [300, 300], 'fluid'], 'div-gpt-ad-nehnutelnosti-mobile-banner-list-4').defineSizeMapping(mobileTabletOnly).addService(window['googletag'].pubads());
                            sasSlots['banner-quick-links-1'] = window['googletag'].defineSlot('/31040149,22425509359/nehnutelnosti/desktop/banner-quick-links-1', [[300, 300]], 'div-gpt-ad-nehnutelnosti-desktop-banner-quick-links-1').setCollapseEmptyDiv(true,true).defineSizeMapping(desktopOnlyQL).addService(window['googletag'].pubads());
                            sasSlots['banner-quick-links-2'] = window['googletag'].defineSlot('/31040149,22425509359/nehnutelnosti/desktop/banner-quick-links-2', [[300, 300]], 'div-gpt-ad-nehnutelnosti-desktop-banner-quick-links-2').setCollapseEmptyDiv(true,true).defineSizeMapping(desktopOnlyQL).addService(window['googletag'].pubads());
                            break;
                        case 'hp':
                            sasSlots['screen-top'] = window['googletag'].defineSlot('/31040149,22425509359/nehnutelnosti/desktop/screen-top', [[728, 200], [970, 250], [970, 90], [990, 300], [990, 250], [990, 200], [990, 90], [1020, 200], [1200, 400]], 'div-gpt-ad-nehnutelnosti-desktop-screen-top').setCollapseEmptyDiv(true,true).defineSizeMapping(desktopOnlyST).addService(window['googletag'].pubads());
                            sasSlots['mobile-middle'] = window['googletag'].defineSlot('/31040149,22425509359/nehnutelnosti/mobile/mobile-middle', [[250, 250], [300, 50], [300, 100], [300, 150], [300, 250], [300, 300], 'fluid'], 'div-gpt-ad-nehnutelnosti-mobile-mobile-middle').defineSizeMapping(mobileTabletOnly).addService(window['googletag'].pubads());
                            sasSlots['mobile-bottom'] = window['googletag'].defineSlot('/31040149,22425509359/nehnutelnosti/mobile/mobile-bottom', [[250, 250], [300, 50], [300, 100], [300, 150], [300, 250], [300, 300], 'fluid'], 'div-gpt-ad-nehnutelnosti-mobile-mobile-bottom').defineSizeMapping(mobileTabletOnly).addService(window['googletag'].pubads());
                            break;
                        }
                    }
                };

                window['googletag'].cmd.push(function () {
                    defineAds();

                    function checkUrlForBannerTest() {
                        if (window.location.hash.indexOf('displayTestBanners')>-1) { document.cookie='displayTestBanners=true; max-age=31536000; path=/'; window.location.href.substr(0, window.location.href.indexOf('#')); }
                        if (window.location.hash.indexOf('hideTestBanners')>-1) { document.cookie='displayTestBanners=true; max-age=0; path=/'; window.location.href.substr(0, window.location.href.indexOf('#')); }
                    }
                    window.addEventListener('hashchange', checkUrlForBannerTest, false);
                    window.addEventListener('DOMContentLoaded', checkUrlForBannerTest, false);
                    if(document.cookie.indexOf('displayTestBanners=true')>-1) { window['googletag'].pubads().setTargeting('a_oth', ['display-test-banners']); }

                    ['adsPag', 'adsSec', 'adsCid', 'adsCat', 'adsKey', 'adsLoc', 'adsSeg'].forEach(function (param) {
                        if (window[param]) {
                            window['googletag'].pubads().setTargeting('c_' + param.substring(3).toLowerCase(), window[
                                param]);
                        }
                    });

                    window['googletag'].pubads().enableLazyLoad({fetchMarginPercent: -1, renderMarginPercent: 50, mobileScaling: 1.0});
                    window['googletag'].pubads().enableSingleRequest();
                    window['googletag'].pubads().collapseEmptyDivs();
                    window['googletag'].enableServices();
                });

                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const updateAdsTargeting = ()=> {
                    window['googletag'].cmd.push(function() {
                        console.log('updateAdsTargeting called');
                        window['googletag'].pubads().disableInitialLoad();
                        window['googletag'].pubads().clearTargeting();
                        window['googletag'].destroySlots();
                        defineAds();
                        if(document.cookie.indexOf('displayTestBanners=true')>-1) { window['googletag'].pubads().setTargeting('a_oth', ['display-test-banners']); }
                        ['adsPag', 'adsSec', 'adsCid', 'adsCat', 'adsKey', 'adsLoc', 'adsSeg'].forEach(function(param) {
                            if (window[param]) {
                                window['googletag'].pubads().setTargeting('c_' + param.substring(3).toLowerCase(), window[param]);
                            }
                        });
                        window['googletag'].pubads().refresh();
                    });
                };
            };

            const loadGoogleDoubleClick = async () => {

                window['PWT'] = {}; //Initialize Namespace
                window['googletag'] = window['googletag'] || {};
                window['googletag'].cmd = window['googletag'].cmd || [];
                let gptRan = false;
                window['PWT'].jsLoaded = function () { //PubMatic pwt.js on load callback is used to load GPT
                    loadGPT();
                };
                const loadGPT = function () {
                    // Check the gptRan flag
                    if (!gptRan) {
                        gptRan = true;
                        const gads = document.createElement('script');

                        gads.onload = function () {
                            let googleTagEventInit;
                            if (typeof (Event) === 'function') {
                                googleTagEventInit = new Event('googleTagEventInit');
                            } else {
                                googleTagEventInit = document.createEvent('Event');
                                googleTagEventInit.initEvent('googleTagEventInit', true, true);
                            }
                            document.dispatchEvent(googleTagEventInit);
                        };

                        const useSSL = 'https:' == document.location.protocol;
                        gads.src = (useSSL ? 'https:' : 'http:') + '//www.googletagservices.com/tag/js/gpt.js';
                        const node = document.getElementsByTagName('script')[0];
                        node.parentNode.insertBefore(gads, node);
                    }
                };
                // Failsafe to call gpt
                setTimeout(loadGPT, 500);

                (function () {
                    const purl = window.location.href;
                    const url = '//ads.pubmatic.com/AdServer/js/pwt/160856/5267';
                    let profileVersionId = '';
                    if (purl.indexOf('pwtv=') > 0) {
                        const regexp = /pwtv=(.*?)(&|$)/g;
                        const matches = regexp.exec(purl);
                        if (matches.length >= 2 && matches[1].length > 0) {
                            profileVersionId = '/' + matches[1];
                        }
                    }
                    const wtads = document.createElement('script');
                    wtads.async = true;
                    wtads.type = 'text/javascript';
                    wtads.src = url + profileVersionId + '/pwt.js';
                    const node = document.getElementsByTagName('script')[0];
                    node.parentNode.insertBefore(wtads, node);
                })();
            };

            // cakame na docitanie googledoubleclick, publicmaticu PWT
            loadGoogleDoubleClick().then(() => {
                loadHead();
            });

        }
    }, [props.adsPag, props.adsSec]);

    return null;
};

export default Head;